@use "theme/variables" as *;

.c-file-drop-area {
    background-color: $surface-6;
    background-image: url("/img/upload.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px;
    border-color: $default-4;
    border-style: dashed;
    border-width: $spacer-025;
    color: $info-2;
    font-size: $font-s;
    font-weight: 600;
    height: $spacer-8;
    max-width: 250px;
    padding: $spacer-2 $spacer-1;
    position: relative;
    width: $spacer-8 * 2;

    .header {
        font-size: $font-xl;
    }

    input {
        cursor: pointer;
        filter: alpha(opacity=0);
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}
