@use "theme/variables" as *;

.c-collection-splsl-suggestions {

    .breadcrumbs {
        align-items: center;
        background: $surface-5;
        display: flex;
        font-weight: 500;
        height: $spacer-6;
        padding: $spacer-1;

        .breadcrumb {
            align-items: center;
            color: $grey-3;
            cursor: pointer;
            display: flex;
            transition: color 0.2s ease-in-out;

            &:hover {
                color: $info-2;
            }


            &:last-child {
                color: $info-2;
            }
        }
    }
}
