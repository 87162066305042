@use "theme/variables" as *;

.c-amount-unit {
    display: flex;
    flex-direction: column;
    font-size: $font-s;
    gap: $spacer-05;

    .c-icon {
        opacity: 0.5;
    }

    .alt-currency,
    .amount-case,
    .amount-bottle {
        align-items: center;
        display: flex;
        gap: $spacer-05;
    }

    .amount-case {
        font-weight: 600;
    }

    .alt-currency {
        color: $info-1;
        margin-top: -1 * $spacer-1;

        abbr {
            text-decoration: none;
        }
    }

    .amount-excise {
        display: flex;
        flex-direction: column;

        .help-block {
            color: $info-1;
            font-size: $font-xs;
            margin: 0;
        }
    }
}
