@use "theme/variables" as *;

.c-manage-buttons {
    position: relative;

    .c-file-drop-area {
        position: absolute;
        right: 0;
        width: $spacer-8 * 3;
    }
}
