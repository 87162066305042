@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-memo {
    align-items: stretch;
    display: flex;
    gap: $spacer-1;

    .memo {
        align-items: center;
        border: 1px solid;
        display: inline-flex;
        font-size: $font-xs;
        gap: $spacer-1;
        line-height: 1.5;
        padding: $spacer-1;
        width: 100%;

        .memo-content {
            flex: 1;
            hyphens: auto;
            width: 100%;
        }

        .memo-icon {
            @include square($icon-xl);
        }

        &.info {
            background: $info-5;
            border-color: $info-4;

            .memo-icon {
                color: $info-3;

                &:hover {
                    color: $info-2;
                }
            }
        }

        &.warning {
            background: $danger-5;
            border-color: $danger-4;

            .memo-icon {
                color: $danger-3;

                &:hover {
                    color: $danger-2;
                }
            }
        }
    }

    &.multiline {

        .memo-icon {
            cursor: ns-resize;
        }
    }

    &.collapsed {

        .memo-content {
            -webkit-box-orient: vertical;
            /* stylelint-disable-next-line value-no-vendor-prefix */
            display: -webkit-box;
            hyphens: auto;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .memo-icon {
            cursor: ns-resize;
        }
    }
}
