@use "theme/variables" as *;

.c-stepper {
    align-items: center;
    display: flex;
    flex: 1;

    .steps {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;

        .progress-line {
            background: $success-2;
            position: absolute;
        }

        .step {
            align-items: center;
            border: 2px solid;
            border-radius: 50%;
            color: $default-2;
            cursor: pointer;
            display: flex;
            font-size: $font-l;
            font-weight: 600;
            justify-content: center;
            transition: all 0.3s ease-out;
            z-index: 2;

            &,
            a {
                background: $default-5;
                border-color: $default-3;
                color: $default-2;

                &:hover {
                    background: $default-3;
                    border-color: $default-1;
                    color: $default-1;
                }
            }

            a {
                align-items: center;
                border-radius: 50%;
                display: flex;
                height: 100%;
                justify-content: center;
                width: 100%;
            }

            &.active {

                &,
                a {
                    background-color: $success-4;
                    border-color: $success-2;
                    color: $success-1;
                    cursor: default;
                }
            }

            &.disabled {

                &,
                a {
                    background-color: $default-5;
                    border-color: $default-4;
                    color: $default-4;
                    cursor: not-allowed;
                }
            }

            &.warning {

                &,
                a {
                    background-color: $warning-5;
                    border-color: $warning-3;
                    color: $warning-2;
                }
            }

            &.completed {

                &,
                a {
                    background-color: $success-2;
                    border-color: $success-2;
                    color: $success-5;
                    cursor: pointer;

                    &:hover {
                        background-color: $success-1;
                        border-color: $success-1;
                        color: $success-6;
                    }
                }

                &.disabled,
                &.disabled a {
                    background-color: $success-4;
                    border-color: $success-3;
                    color: $success-3;
                    cursor: not-allowed;
                }
            }
        }
    }

    &.size-d {

        .step {
            height: $spacer-6;
            min-width: $spacer-6;
            width: $spacer-6;
        }
    }

    &.horizontal {
        justify-content: center;

        .progress-line {
            height: 2px;
            top: $spacer-3;
            width: 100%;
            z-index: 1;
        }
    }

    &.vertical {
        align-items: center;
        justify-content: center;

        .steps {
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .progress-line {
            flex-direction: column;
            gap: $spacer-1;
            height: 100%;
            left: $spacer-3 - 1px;
            width: 2px;
        }
    }
}
