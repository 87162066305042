@use "theme/variables" as *;

.c-cell-dimension {

    &.td-group {
        display: flex;
        justify-content: center;

        .dimension-info {
            align-items: flex-start;
            display: flex;
            font-size: $font-xs;
            gap: $spacer-05;

            .stat {
                display: flex;
                gap: $spacer-05;
            }
        }
    }
}
