@use "theme/variables" as *;

.c-data-card {
    display: flex;
    flex-direction: column;
    gap: $spacer-025;
    position: relative;
    width: 100%;
    z-index: 10;

    .entry {
        display: flex;
        padding: $spacer-1;
        width: 100%;

        .entry-label {
            display: flex;
            flex: 2;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .entry-value {
            display: flex;
            flex: 4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.variant-dense {
        gap: 0;

        .entry {
            padding: $spacer-05 $spacer-1;
        }
    }

    &.variant-extra-dense {
        gap: $spacer-025;

        > :nth-child(even) {
            background: none;
        }

        .entry {
            padding: $spacer-05 $spacer-025;

            .entry-label {
                flex: unset;
                margin-right: $spacer-05;
            }
        }
    }

    &.variant-vertical {

        .entry {
            flex-direction: column;
            padding: $spacer-05 $spacer-1;
        }
    }

    &.type-default {

        > :nth-child(even) {
            background: $default-6;
        }

        > :nth-child(odd) {
            background: $default-5;
        }

        .entry {

            .entry-label {

                svg {
                    color: $surface-3;
                }
            }
        }
    }

    &.type-info {

        > :nth-child(even) {
            background: $info-6;
        }

        > :nth-child(odd) {
            background: $info-5;
        }

        .entry {
            color: $info-2;

            .entry-label {

                svg {
                    color: $info-3;
                }
            }
        }
    }

    &.type-success {

        > :nth-child(even) {
            background: $success-6;
        }

        > :nth-child(odd) {
            background: $success-5;
        }

        .entry {
            color: $success-2;

            .entry-label {

                svg {
                    color: $success-3;
                }
            }
        }
    }

    &.type-warning {

        > :nth-child(even) {
            background: $warning-6;
        }

        > :nth-child(odd) {
            background: $warning-5;
        }

        .entry {
            color: $warning-2;

            .entry-label {

                svg {
                    color: $warning-3;
                }
            }
        }
    }
}
