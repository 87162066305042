@use "theme/variables" as *;

.c-process-stepper {

    .step {
        align-items: center;
        box-shadow: $shadow-down-d;
        display: flex;
        padding: $spacer-2;
        text-decoration: none;
        transition: all 0.3s ease;

        .title {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:not(.active) {
            background: $default-6;
            color: $default-2;

            &:not(.disabled):hover {
                background: $info-6;
                color: $info-2;
            }
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &.active {
            background: $info-2;
            color: $info-5;
            cursor: default;
        }

        &.completed {
            background: $success-5;
            color: $success-1;
        }

        &.type-danger {
            background: $danger-5;
            color: $danger-1;
        }
    }


    &.variant-horizontal {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer-2;

        .step {
            align-items: center;
            box-shadow: $shadow-down-d;
            display: flex;
            font-weight: 600;
            gap: $spacer-1;
            padding: $spacer-1 $spacer-2;
            text-decoration: none;
            transition: all 0.3s ease;
        }
    }

    &.variant-vertical {
        max-width: 300px;
        width: 100%;

        .step {
            margin-bottom: $spacer-2;
        }
    }
}
