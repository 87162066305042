@use "theme/variables" as *;

.c-field-composed-product {
    margin-bottom: $spacer-3;

    .search-container {
        align-items: center;
        background: $info-5;
        border: 1px solid $info-4;
        padding: $spacer-05;

        .field {

            label {
                color: $info-1;
            }
        }
    }

    .product-container {
        align-items: center;
        background: $info-5;
        border: 1px solid $info-4;
        display: flex;
        justify-content: space-between;
        padding: $spacer-1;

        label {
            width: 100%;
        }

        .product {
            color: $info-1;
            width: 100%;

            .product-header {
                display: flex;
                font-weight: 600;
                gap: $spacer-05;
            }

            .product-specs {
                margin-bottom: $spacer-1;
            }

            .product-details {
                align-items: center;
                display: flex;
                font-size: $font-s;
                font-style: italic;
                gap: $spacer-05;
                margin-bottom: $spacer-025;
            }
        }
    }
}
