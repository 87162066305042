@use "theme/variables" as *;

.c-margin {

    label {
        font-weight: 600;
        margin-right: $spacer-05;
    }

    .amount {

        &.rating-warning {
            color: $warning-25;
        }

        &.rating-danger {
            color: $danger-25;
        }

        &.rating-success {
            color: $success-25;
        }
    }
}
