@use "theme/variables" as *;

html body .c-button-group {
    align-items: center;
    display: flex;

    &.type-default {

        .c-button {
            border-left-width: 0;
            border-radius: 0;

            &:first-child {
                border-left-width: 1px;
            }
        }
    }

    &.type-panel {
        gap: $spacer-1;

        .c-button {
            flex: 1;
        }
    }

    &.type-bar {
        box-shadow: $shadow-down-d-l;
    }

    &.type-context {
        box-shadow: $shadow-down-d;
        display: inline-flex;
        margin-bottom: $spacer-2;

        // Multiple groups may be positioned next to each other
        margin-right: $spacer-2;

        .c-button {
            max-width: 100px;
        }
    }

    &.type-simple {

        .c-button {
            border-radius: 0;

            &:last-child {
                border-radius: 0;
            }
        }
    }

    &.type-spaced {
        gap: $spacer-1;
    }
}

