@use "theme/variables" as *;

.c-attachment-list {
    align-items: start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: $spacer-1;
    width: 100%;
}
