@use "theme/variables" as *;

.filter {
    width: 100%;

    * {
        user-select: none;
    }

    .help-text {
        color: $default-2;
        font-size: $font-xs;
        font-style: italic;
        font-weight: 300;
    }

    .title {
        align-items: center;
        background: $grey-6;
        display: flex;
        height: $spacer-6;
        justify-content: space-between;
        padding: $spacer-1;
        position: relative;

        .name {
            align-items: center;
            color: $grey-3;
            display: flex;
            font-weight: 500;
            transition: color 0.3s ease;


            .btn-filter-toggle {

                &:focus {
                    outline: none;
                }

                &:hover {
                    cursor: default;
                }

                &.active {
                    color: $brand-3;
                }
            }

            svg {
                margin-right: $spacer-1;
            }

            .count {
                margin-left: $spacer-05;
            }
        }

        .name,
        .name svg,
        .btn-collapse {
            color: $info-1;
        }

        &:hover {
            cursor: n-resize;
        }
    }

    .items {
        border-top: 2px solid $default-5;
        max-height: 250px;
        overflow-x: auto;
        padding: 0;
        transition: max-height 0.3s ease-out;

        .c-checkbox-group,
        .c-radio-group {
            padding: $spacer-1 0;
        }

        .item {
            color: $grey-1;
            display: flex;
            padding: $spacer-05 $spacer-1;
            transition: color 0.5s;

            .radio,
            .checkbox {
                flex: 1;
                margin: 0;
            }

            label {
                display: block;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .count {
                align-items: center;
                color: $grey-3;
                display: flex;
                font-size: $font-s;
                justify-content: flex-end;
            }


            &.disabled {

                label {
                    color: $grey-4;
                }
            }
        }
    }


    &.non-collapsable,
    &.collapsed {

        &:not(.active):not(.disabled) {

            .title {

                &:hover {
                    cursor: s-resize;
                }

                .name,
                .name svg,
                .btn-collapse {
                    color: $grey-3;
                }
            }
        }


        .items {
            max-height: 0;
            overflow: hidden;
        }
    }

    &.active {

        .title {

            .name .btn-filter-toggle:hover {
                cursor: pointer;
            }

            .name,
            .name svg,
            .btn-collapse {
                color: $brand-3;
            }
        }

        &.collapsed {

            .title {

                .name .btn-filter-toggle:hover svg {
                    color: $brand-4;
                }
            }
        }
    }

    &.disabled {

        .title {
            background: $grey-6;
            color: $grey-4;

            .btn-filter-toggle {
                cursor: not-allowed;
            }

            .name,
            .name svg,
            .btn-collapse {
                color: $grey-4;
            }

            .btn-collapse {
                display: none;
            }
        }
    }

    &.loading {

        .items {

            input {
                border: 1px solid $grey-4;
            }

            .count,
            .item {
                color: $grey-4;
            }
        }
    }
}

