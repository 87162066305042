@use "theme/variables" as *;

.c-collection-hero {
    container-name: collection-hero;
    container-type: inline-size;
    display: flex;
    font-size: $font-s;
    gap: $spacer-1;

    .memo-section {
        flex: 1;
        width: 100%;
    }
}

@container collection-hero (width < 1500px) {
    /* Change the flex direction of the .child element. */

    .memo-section {
        flex-direction: row;

        .c-data-card {

            .entry {
                flex-direction: column;
                padding: $spacer-05 $spacer-1;
            }
        }
    }
}
