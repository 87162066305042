@use "theme/variables" as *;

.sales.orders.c-view-manage {

    &.context-default {

        .c-panel-filters.context-create,
        .c-collection-view.context-create {
            display: none;
        }
    }

    &.context-create {

        .c-panel-filters {

            .filter-search {
                display: none;
            }
        }

        .c-panel-filters.context-default,
        .c-collection-view.context-default {
            display: none;
        }
    }

    .c-panel-context {

        .c-field-select {
            max-width: unset;
            width: 100%;
        }

        .c-cell-product {
            background: $info-5;
            border: 1px solid $info-4;
            margin-bottom: $spacer-2;
            padding: $spacer-1;
        }
    }

    .manage-panel-context {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;

        .c-tabs {
            height: 100%;

            .panels {
                display: flex;
                flex: 1;
                height: 100%;

                .c-tab-panel {
                    flex: 1;
                    height: 100%;
                }
            }
        }
    }
}
