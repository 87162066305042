@use "theme/variables" as *;

.c-sourceline-info {
    display: flex;

    .audit-logs {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        font-weight: 600;
        justify-content: center;
        line-height: $spacer-3;
        text-align: center;
        width: 50%;

        .details {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: $spacer-1;
            justify-content: center;
            padding: $spacer-1;
            width: 100%;

            a {
                cursor: pointer;
            }

            span {
                text-align: center;
            }

            .audit-line {
                width: 100%;
            }
        }
    }

    .c-data-card {
        width: 50%;
    }

    &.type-warning {
        background: $warning-4;

        .audit-logs {
            color: $warning-2;
        }
    }

    &.type-success {
        background: $success-4;

        .audit-logs {

            &,
            & a {
                color: $success-2;
            }
        }
    }
}
