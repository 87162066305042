@use "theme/variables" as *;

.c-purchase-order-items {

    .c-specs-edit {
        display: flex;
        flex-direction: row;
        gap: $spacer-025;
        padding-bottom: $spacer-1;

        .refill-select {
            width: 50%;
        }

        .number-input {
            min-width: 48px;
            width: 100%;
        }
    }

    .fl {

        &.warning {
            color: $warning-25;
        }
    }

    .price-detail {
        font-weight: 500;
    }
}
