@use "theme/variables" as *;

.c-quantity {
    display: flex;
    flex-direction: column;
    font-size: $font-s;
    gap: $spacer-05;

    .c-icon {
        opacity: 0.5;
    }

    .case-quantity,
    .bottle-quantity {
        align-items: center;
        display: flex;
        gap: $spacer-05;
    }

    .case-quantity {
        font-weight: 600;
    }
}
