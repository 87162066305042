@use "theme/variables" as *;

.c-stock-context {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;

    .header {
        align-items: center;
        display: flex;
        font-weight: 600;
    }

    .value {
        font-weight: 500;
    }
}
