@use "theme/variables" as *;

.c-dialog-settings .body {
    display: flex;
    flex-direction: column;
    gap: $spacer-2;
    justify-content: flex-end;
    min-width: 550px;

    .confirmation-row {
        background: $surface-5;
        border: 1px solid $surface-4;
        display: flex;
        flex-flow: column;

        .c-field-switch {
            flex-direction: row-reverse;
            gap: $spacer-1;
        }

        .label {
            align-items: center;
            background: $grey-4;
            color: $grey-3;
            display: flex;
            font-weight: 800;
            gap: $spacer-1;
            padding: $spacer-1;

            .c-checkbox {
                margin-bottom: 0;
            }
        }

        .value {
            display: none;
            flex-direction: row;
            gap: $spacer-1;
            justify-items: left;

            .field {
                margin: $spacer-1;
            }

            .c-checkbox {
                flex-grow: 0;
            }
        }

        &.active {
            background: $info-5;


            .label {
                background: $info-4;
                color: $info-2;
            }

            .value {
                display: flex;
            }
        }

        &.completed {
            background: $success-5;

            .label {
                background: $success-4;
                color: $success-2;
            }

            .value {
                display: none;
            }
        }

        &.disabled {

            .value {
                display: none;
            }
        }
    }
}
