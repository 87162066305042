@use "theme/variables" as *;
@use "common/lib/mixins" as *;

$vertical-offset: $spacer-3;

.c-timeline {

    .timeline {
        border-right: 2px solid $surface-3;
        padding-top: $spacer-5;
        position: relative;

        .now {
            background: $surface-2;
            border-left: 0;
            color: $info-6;
            font-weight: 600;
            margin-bottom: $spacer-1;
            padding: $spacer-05 $spacer-1;
            position: absolute;
            right: -10px;
            top: 0;
            width: 100px;
        }

        .timeline-item {
            margin-bottom: $spacer-2;
            margin-right: $spacer-2;
            position: relative;

            .timeline-icon {
                @include square($icon-s);

                background: $surface-5;
                border-radius: 50%;
                color: $surface-2;
                position: absolute;
                right: -26px;
                top: $vertical-offset;
            }

            .entry-date {
                color: $grey-2;
                font-weight: 600;
            }

            .timeline-message {
                background: $white;
                border: 2px solid $surface-4;
                padding: $spacer-2;

                .message-header {
                    align-items: center;
                    color: $surface-2;
                    display: flex;
                    font-weight: 600;
                    justify-content: space-between;


                    .message-header-left {
                        align-items: center;
                        display: flex;
                        flex: 1;
                        gap: $spacer-05;
                    }

                    .message-header-right {
                        align-items: center;
                        display: flex;
                        gap: $spacer-05;

                        .c-button {
                            @include square($icon-xl);
                        }
                    }
                }

                .message-content {
                    color: $grey-1;
                }
            }
        }
    }

    .entry {
        padding-bottom: $spacer-3;

        .entry-header {
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}
