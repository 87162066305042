@use "theme/variables" as *;

.c-list-manage {

    .c-panel-context {

        .c-stats {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: $spacer-3;
            width: 100%;
        }
    }
}
