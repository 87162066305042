@use "theme/variables" as *;

.c-add-to-order {
    padding: $spacer-1 $spacer-2;
    width: 100%;

    .c-data-card {
        background-color: $surface-4;
        margin-bottom: $spacer-1;
        padding: $spacer-025;
    }
}
