@use "theme/variables" as *;

.c-add-to-offer {
    width: 100%;

    .datacard-container {
        background: $surface-4;
        display: flex;
        margin-bottom: $spacer-2;
        padding: $spacer-05;

        // width: 100%;

        .c-data-card {
            // padding: $spacer-025;
        }
    }

    &.type-dialog {
        min-width: 700px;
    }
}

