@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.c-bar-main {
    align-items: center;
    background: $info-1;
    display: flex;
    padding-left: var(--query-spacer);
    position: relative;
    user-select: none;
    width: 100%;
    z-index: 10000; // (!) my-portal dropdown must be on top of PanelContext

    select {
        border: none;
        outline: none;
    }

    button.variant-menu {

        svg {
            color: $default-1;
        }

        &.active,
        &:hover {
            background: $info-1;
            border-bottom-color: $info-1;
        }
    }

    .bar-search {
        align-items: center;
        display: flex;
        flex: 10;
        height: $layout-header-height;  // keeps c-bar-main from shifting height
        max-width: 604px;

        .search-box input {
            background: none;
            border: none;
            border-bottom: 1px solid $default-3;
            border-radius: 0;
            box-shadow: none;
            color: $default-5;
            opacity: 0.7;
            outline: none;

            // Reserve space for the absolutely positioned search/clear buttons.
            padding: 0 $spacer-4;
            transition: opacity 0.3s ease;
            width: 100%;

            &::first-letter {
                text-transform: capitalize;
            }

            &::placeholder {
                color: $default-5;
                opacity: 1;
            }

            &:focus {
                border-bottom: 1px solid $default-4;
                opacity: 1;

                ~ button[disabled] {

                    svg {
                        color: $grey-3;
                    }
                }
            }
        }

        .btn-search {
            @include square($spacer-4);

            color: $surface-4;
            position: absolute;
        }

        .btn-clear {
            @include square($spacer-3);

            color: $surface-3;
            position: absolute;
            right: $spacer-1;
        }
    }

    .action-group {
        align-items: center;
        display: flex;
        margin-left: var(--query-spacer);

        .btn-dropdown {
            cursor: pointer;
            display: flex;

            svg {
                color: $info-4;

                &.active,
                &:hover {
                    color: $info-6;
                }
            }
        }

        .dropdown-body {
            background: $default-5;
            border: none;
            min-width: 200px;

            header {
                align-items: center;
                background: $info-1;
                color: $default-4;
                display: flex;
                height: $spacer-6;
                padding: $spacer-1;
            }

            .divider {
                background: $default-4;
                height: 1px;
            }
        }

        .btn-action {
            color: $default-4;
            height: $spacer-6;
            transition: all 0.3s ease;

            &:hover,
            &.active {
                color: $white;
                filter: drop-shadow(0 0 4px $info-1);
            }
        }

        .dropdown-my-portal {
            cursor: pointer;

            .dropdown-body {

                .c-button {
                    justify-content: flex-start;
                    padding: $spacer-1;
                    text-align: left;
                    width: 100%;
                }
            }
        }

        .dropdown-cart {

            &.has-options {
                cursor: context-menu;
            }

            .dropdown-button {

                .amount-unit {
                    bottom: $spacer-1;
                    position: absolute;
                    right: -1.5 * $spacer-1;
                }
            }

            .dropdown-body {

                .c-button {
                    padding: $spacer-1;
                }

                .cart {
                    align-items: center;
                    display: flex;
                    height: $spacer-6;
                    justify-content: space-between;
                    padding: $spacer-1;

                    .order-details {
                        flex: 1;
                        margin-left: $spacer-1;
                    }

                    .cart-items {
                        display: flex;
                        gap: $spacer-05;
                    }

                    &:hover {
                        background: $brand-4;
                        color: $default-5;
                        cursor: pointer;
                    }

                    &.active {
                        background: $brand-3;
                        color: $default-5;
                    }
                }
            }
        }
    }

    .bar-header {
        align-items: center;
        color: $default-5;
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: flex-end;

        .title {
            align-items: center;
            display: flex;
            font-size: $font-l;
            font-weight: 700;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
        }

        .page-icon {
            align-items: center;
            background: $brand-3;
            display: flex;
            height: 100%;
            justify-content: center;
            margin-left: var(--query-spacer);
            width: $panel-collapsed-width;
        }
    }

    &.legacy-account {
        background: $warning-legacy-3;

        .search-bar {

            svg {
                color: $warning-legacy-1;
            }
        }

        select,
        .search-bar,
        .search-bar input,
        .search-bar input::placeholder {
            background: $warning-legacy-4;
            color: $warning-legacy-1;
        }

        .bar-header {

            .title {
                color: $warning-legacy-1;
            }

            .action-group {

                svg {
                    color: $warning-legacy-1;
                }
            }
        }
    }

    @media (max-width: $breakpoint-20) {

        .bar-header {

            .title {
                display: none;
            }
        }
    }

    @media (max-width: $breakpoint-10) {

        .bar-header {

            .page-icon {
                display: none;
            }
        }

        .action-group {
            margin-right: var(--query-spacer);
        }
    }
}
