@use "theme/variables" as *;

$gradient-1: hsl(var(--info-h) var(--s-3) var(--l-1));
$gradient-2: hsl(var(--info-h) var(--s-3) calc(var(--l-1) + 2%));

.c-dialog {
    align-items: center;
    backdrop-filter: blur(2px);
    background: rgb(0 0 0 / 50%);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10000000;

    .btn-close {
        color: $surface-6;

        &:hover {
            color: $surface-4;
        }
    }


    &.type-danger .dialog .title {
        background: $danger-25;
        color: $danger-6;
    }

    &.type-default .dialog .title {
        background: hsl(var(--surface-h) var(--s-2) var(--l-1));
    }

    &.type-info .dialog .title {
        background: $info-25;
        color: $info-6;
    }

    &.type-success .dialog .title {
        background: $success-25;
        color: $success-6;
    }

    &.type-warning .dialog .title {
        background: $warning-25;
        color: $warning-6;
    }


    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance:none) {
            /* stylelint-disable-next-line value-no-vendor-prefix */
            height: -webkit-fill-available;
        }
    }

    .dialog {
        background: $default-5;
        box-shadow: $shadow-down-l;
        max-height: 95vh;
        max-width: $breakpoint-20;
        overflow: auto;

        .title {
            align-items: center;
            border-bottom: 1px $default-1;
            color: $default-4;
            display: flex;
            height: $spacer-6;
            justify-content: space-between;
            padding: $spacer-1;

            .title-text {
                font-size: $font-l;
                text-transform: uppercase;
            }


            &::after {
                clear: both;
            }
        }

        .body {
            padding: $spacer-1 * 2;
            position: relative;

            img {
                width: 100%;
            }
        }

        .footer {
            padding: $spacer-1;

            .c-button-group {
                justify-content: flex-end;
            }
        }
    }

    @media (max-width: $breakpoint-20) {
        align-items: center;
        margin: 0;
        width: 100%;

        .dialog {
            width: 100%;
        }
    }
}

