@use "theme/variables" as *;

.c-order-summary {
    border: 1px solid $info-4;
    display: flex;
    flex-direction: column;
    margin-top: $spacer-2;
    padding: 0 $spacer-025;

    .c-data-card {

        .entry-label {
            padding: 0 $spacer-1;
        }
    }

    .sales-order-items-summary {

        .break-line {
            background: $surface-6;
        }

        .product-line {
            padding: $spacer-05 $spacer-1;

            &:nth-of-type(odd) {
                background: $surface-6;
            }

            &:nth-of-type(even) {
                background: $surface-5;
            }
        }
    }
}
