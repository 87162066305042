@use "theme/variables" as *;

.c-cell-product {
    display: flex;
    flex-direction: column;

    &.td-group {
        display: flex;
        justify-content: center;

        .header {
            color: $info-2;
        }

        .details {
            font-size: $font-s;
            font-weight: italic;
        }
    }
}
