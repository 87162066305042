@use "theme/variables" as *;

.c-checkbox {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    label {
        flex: 1 0;
    }

    &.select-intend {

        > input {
            background: $brand-4;
        }
    }

    &.type-success {

        > input[type="checkbox"] {
            background: $success-6;
            border: 1px solid $success-2;
            color: $success-2;

            &::after {
                border-color: $success-2;
                color: $success-2;
            }
        }
    }
}

